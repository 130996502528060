import * as React from "react";
import { graphql } from "gatsby";
import type { HeadFC } from "gatsby";
import useCookieState from "../hooks/useCookieState";
import usePageData from "../hooks/usePageData";
import { PropsType } from "../hooks/propsType";
import Seo from "../components/SEO";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Insights from "../components/Insights";
import ShopList from "../components/ShopList";
import ShopProduct from "../components/ShopProduct";
import Comparisons from "../components/Comparisons";
import { transformedData, shuffleArray } from "../utils/functions";
import "../assets/scss/index.scss";

const Page: React.FC<PropsType> = ({ data, pageContext }) => {
  const [compareList, setCompareList] = useCookieState(
    "compare",
    JSON.stringify([])
  );
  const [openModal, setOpenModal] = React.useState(false);

  const { settings, articles, products, productsMeta, pageSlug, curentPage } =
    usePageData(data, pageContext);

  return (
    <div className="tf">
      <Header
        settings={transformedData(settings?.header)}
        settingsFooter={transformedData(settings?.footer)}
        products={products}
        compareList={compareList}
        setCompareList={setCompareList}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
      <ShopProduct
        product={products?.filter((e: any) => e?.slug === pageSlug)}
        productsSettings={transformedData(settings?.products)}
        products={products}
        brands={transformedData(settings?.awards)}
        compareList={compareList}
        setCompareList={setCompareList}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
      <Comparisons
        products={products}
        productsSettings={{
          ...transformedData(settings?.products),
          comparePageTitle: transformedData(settings?.products)
            .comparePageSubtitleTitlePro,
          comparePageSubtitleTitle: transformedData(settings?.products)
            .comparePageTitlePro,
          comparePageContent: transformedData(settings?.products)
            .comparePageContentPro,
        }}
        button={true}
        brand={products?.filter((e: any) => e?.slug === pageSlug)[0]?.brand}
        limit={9}
        light={false}
        sector="pro"
      />
      {transformedData(settings?.products).similarProducts.map(
        (e: any, i: any) => {
          const filteredProducts = shuffleArray(productsMeta)
            ?.filter((product: any) =>
              product?.metadata?.find(
                (field: any) =>
                  field.key === e?.tag &&
                  field.value ===
                    productsMeta
                      ?.filter((e: any) => e?.slug === pageSlug)[0]
                      ?.metadata?.find((m: any) => m.key === e?.tag)?.value
              )
            )
            .map((e: any) => {
              return { value: e?.slug };
            })
            .slice(0, 4);
          if (filteredProducts?.length === 0) return null;
          return (
            <ShopList
              settings={{
                subTitle: e?.subTitle,
                title: e?.title,
                content: e?.description,
                listProducts: filteredProducts,
              }}
              key={i}
              products={products}
              productsSettings={transformedData(settings?.products)}
              compareList={compareList}
              setCompareList={setCompareList}
              openModal={openModal}
              setOpenModal={setOpenModal}
              light={!!(i % 2)}
              showButton={false}
            />
          );
        }
      )}
      <Insights
        articles={articles?.slice(0, 3)}
        full={false}
        light={false}
        settings={transformedData(settings?.news)}
      />
      <Footer settings={transformedData(settings?.footer)} />
    </div>
  );
};

export const Head: HeadFC<PropsType> = ({ data, pageContext }) => {
  const { curentPage } = usePageData(data, pageContext);
  const metaData = transformedData(curentPage?.metadata);
  return (
    <Seo title={metaData?.seoTitle} description={metaData?.seoDescription} />
  );
};

export const query = graphql`
  query {
    allMdx(sort: { frontmatter: { date: DESC } }) {
      nodes {
        ...MdxFields
      }
    }
  }
`;

export default Page;
